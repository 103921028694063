export async function apiErrorHandler (self, response) {
    try {
        console.log('This is a shared function');
        let message = `Ошибка запроса: ${response.status}`
        const data = await response.json()
        console.log('data', data)
        if (data.message) {
            message += '. ' + data.message
        }
        self.$toast.error(message);
    } catch (e) {
        self.$toast.error(`Ошибка запроса: ${e.type}: ${e.name}`);
    }
}
<template lang="html">
  <div class="text-center">
    <img 
      v-if="user.profile.photo_url" 
      :src="user.profile.photo_url" 
      alt="avatar" 
      class="rounded-circle mb-3" 
      width="100" 
      height="100">
    
    <svg v-else xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-person-circle opacity-50 mb-3" viewBox="0 0 16 16">
      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
      <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
    </svg>
    <h6 class="mb-0">
      <span v-if="user.first_name || user.last_name">
        {{ user.first_name }}
        {{ user.last_name }}
        <br>
      </span>
      <strong><small class="text-secondary">{{ user.username }}</small></strong>
    </h6>
    <div class="badge text-bg-secondary mb-3">{{ roles[user.profile.role] }}</div>

  </div>
</template>
  
<script>

import moment from 'moment'
moment.locale('ru')
window.moment = moment

export default {
  data () {
    return {
      roles: {
        'admin': 'Админ',
        'supervisor': 'Супервизор (куратор)',
        'instructor': 'Инструктор',
        'student': 'Ученик',
      },
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    },
  },
  methods: {
    
  },
  mounted () {

  },
}
</script>

<style lang="scss">
.user:hover {
  background-color: var(--bs-secondary-bg);
  cursor: pointer;
}
</style>
<template>
  <div 
    class="telegram-login-button" 
    ref="telegramLoginButton">
  </div>
</template>

<script>
export default {
  name: 'TgAuthBtn',
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-widget.js?22'
    script.setAttribute('async', '')
    script.setAttribute('data-telegram-login', 'govori_site_bot')
    script.setAttribute('data-size', 'medium')
    script.setAttribute('data-radius', '8')
    script.setAttribute('data-auth-url', 'https://app.govori.site/api/auth/telegram')
    script.setAttribute('data-request-access', 'write')
    this.$refs.telegramLoginButton.appendChild(script)
  }
}
</script>